import React, { useMemo } from 'react';
import useFetch  from 'use-http'
import isFunction from 'lodash/isFunction'
import Collapsible from 'react-collapsible';

import Box from '../../components/Box'
import Text from '../../components/Text'
import Loading from '../../components/Loading'

const arrToObj = (arr, key) => arr.reduce((all, item) => {
  all[item[key]] = item
  return all
}, {})

const Data = ({ path, children, toObject, loadingSize, loadingText, flex, width }) => {
  const { loading, error, data } = useFetch(path, {
    retries: 5,
    retryDelay: 1000,
    retryOn: [500],
  }, []) // onMount
  if (error) console.error(error)
  const d = useMemo(() => data && toObject ? arrToObj(data, 'id') : data, [data, toObject])
  return (
    <Box position="relative" flex={flex} width={width}>
      {error && (
        <Box py="1em">
          <Collapsible
            trigger={<Text color="danger">發生錯誤，請稍後再試。(...展開詳情)</Text>}
          >
            <Box as="pre" fontSize="0.9em">
              {error.stack}
            </Box>
          </Collapsible>
        </Box>
      )}
      {loading && (
        loadingText ? 'Loading...' : (
          <Box.FullAbsolute bg="rgba(255,255,255,0.5)" zIndex={99}>
            <Box.AbsCenter>
              <Loading color="purple" width={loadingSize} />
            </Box.AbsCenter>
          </Box.FullAbsolute>
        )
      )}
      {isFunction(children) ? children(d, loading) : children}
    </Box>
  )
};

export default Data;
